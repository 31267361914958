<template>
    <div ref="challenge" class="flex flex-col md:flex-row-reverse max-w-screen-lg py-20 mx-auto gap-6 lg:gap-10 items-center px-4">
        
        <object 
            aria-label="smoxy savings goal example image"
            class="flex-1 text-white bg-[#CA54F3] bg-gradient-to-r from-[#7E1EF8] rounded-2xl drop-shadow-xl relative w-full items-center py-6 px-10 gap-6">
            <img
                alt="Trophy icon"
                src="@/assets/icon_island.svg"
                class="w-44 h-44 mx-auto"
                width="226" height="226"
                />
            <p class="text-white/50">{{$t('home.features.savings_goals.duration')}}</p>    
            <p class="font-bold font-owners text-4xl">{{$t('home.features.savings_goals.goal')}}</p>
            <div class="bg-black/20 rounded-2xl w-full h-3 my-4">
                <div class="bg-white w-[25%] h-full rounded-2xl drop-shadow-xl"></div>
            </div>
            <div class="flex flex-row">
                <p class="mr-auto font-owners text-xl">525€</p>    
                <p class="font-bold font-owners text-xl">1.500€</p>  
            </div>   
              
        </object>

        <div class="pt-6 md:pt-0 px-4 md:px-0 flex-1 w-full">
            <p class="text-[#7E1EF8]">{{$t('home.features.savings_goals.title')}}</p>
            <h3 class="text-3xl pr-4 font-bold font-owners text-[#290B50]">{{$t('home.features.savings_goals.headline')}}</h3>
            <p class="pt-4">{{$t('home.features.savings_goals.body')}}</p>
        </div>   
        
        
    </div>
</template>